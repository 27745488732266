<template>
	<div class="container mt-4 mb-4">
		<div class="row">
			<div class="col-12">
				<h1>Termos e Condições de Utilização</h1>
				<p>
					(Actualizado a 01/06/2020)
				</p>

				<p>
					O site {{ domain }} é propriedade da YNEXUS DIGITAL TRIBE, LDA, doravante apenas Ynexus,
					sociedade
					comercial, com sede em Startup Leiria, R. da Carvalha 570, 2400-441 Leiria.
				</p>
				<p>
					A utilização deste site rege-se pelos presentes Termos e Condições, cuja leitura recomendamos, e
					está
					condicionada à aceitação integral dos mesmos.
				</p>
				<p>
					Esclarece-se que, em caso de conflito entre os termos e condições de produtos ou serviços
					específicos
					e
					os
					presentes Termos e Condições, as condições específicas devem prevalecer.
				</p>

				<p class="strong">
					1. OBJECTO
				</p>
				<p>
					Os presentes Termos e Condições descrevem as condições legais aplicáveis a todos os utilizadores que
					acedam a
					este site ( independentemente da forma como seja feito esse acesso).
				</p>
				<p class="strong">
					2. REGRAS DE UTILIZAÇÃO
				</p>
				<p>
					O utilizador compromete-se a não utilizar este site de forma que impossibilite ou perturbe o seu
					funcionamento
					normal e acesso pelos demais utilizadores ou de modo que possa causar, directa ou indirectamente,
					quaisquer
					danos à Ynexus, às empresas suas fornecedoras ou a terceiros utilizadores.
				</p>
				<p>Especificamente, mas não exclusivamente, o utilizador compromete-se, a:</p>
				<ul>
					<li>
						Não reproduzir, distribuir, comercializar, transmitir e/ou disponibilizar qualquer conteúdo do
						site (
						seja ou
						não através de internet) sem a prévia autorização da Ynexus e sem referência aos direitos de
						propriedade
						intelectual;
					</li>
					<li>
						Não utilizar os conteúdos do site de forma que seja considerada ilegal, ou em conjugação ou
						associação
						com
						imagens ou outros conteúdos que contenham pornografia, actos violentos ou de incitação à
						violência,
						actos
						ilícitos, desleais, obscenos ou difamatórios relativamente a quaisquer pessoas e/ou bens ou que
						promovam
						a
						discriminação racial ou sexual;
					</li>
					<li>
						Não utilizar os conteúdos do site em termos que, directa ou indirectamente, violem: (i) direitos
						de
						propriedade
						intelectual da Ynexus ou que possam trazer qualquer impacto negativo à sua imagem.
					</li>
					<li>
						Não alterar nem retirar qualquer informação de direitos de autor, marca, logótipo ou outros
						elementos
						identificativos constantes do site.
					</li>
				</ul>
				<p class="strong">
					3. COPYRIGHT
				</p>
				<p>
					O conteúdo deste website, incluindo marcas, desenhos, logotipos, texto, imagens, é propriedade da
					Ynexus
					ou de
					terceiros que a disponibilizaram, estando protegido, nos termos gerais de direito e pelos normativos
					específicos
					de protecção da Propriedade Intelectual.
				</p>
				<p>
					É expressamente proibido, mostrar, copiar, reproduzir, distribuir, modificar, transmitir ou utilizar
					de
					qualquer
					modo os conteúdos, comercialmente ou não, sem o prévio e expresso consentimento por escrito da
					Ynexus.
				</p>

				<p class="strong">
					4. RESPONSABILIDADE E RISCO
				</p>
				<p>
					A informação disponibilizada neste site ou obtida através dele, embora precisa, não dispensa a
					respectiva
					confirmação e não poderá ser considerada vinculativa. As decisões tomadas com base nesta informação
					serão da
					exclusiva responsabilidade do utilizador.
				</p>
				<p>
					A Ynexus não será responsável perante qualquer utilizador do site, por quaisquer eventuais danos
					(incluindo
					lucros cessantes e danos morais) que advenham da utilização - correcta ou incorrecta – deste site e
					seu
					conteúdo.
				</p>
				<p>
					Pode encontrar no site links externos para sites terceiros. A Ynexus não tem qualquer influência
					sobre a
					concepção e o conteúdo das páginas para as quais é encaminhado, pelo que não pode garantir a
					exatidão,
					correção,
					integridade ou qualidade das informações aí apresentadas. Assim, a Ynexus não é responsável pelo
					conteúdo de
					qualquer desses websites, pelo que o risco do acesso e visita aos mesmos corre por conta do
					utilizador.
				</p>

				<p>
					De igual modo, a Ynexus não será responsável perante o utilizador, por quaisquer prejuízos sofridos
					pelo
					mesmo
					ou por terceiros, resultantes da impossibilidade do uso do site, seja porque motivo for,
					As normas de exclusão de responsabilidade abrangem, também, os gerentes e colaboradores da Ynexus.
				</p>
				<p class="strong">
					5. ALTERAÇÕES
				</p>
				<p>
					A Ynexus reserva-se o direito de, a qualquer momento, sem necessidade de aviso prévio e com efeitos
					imediatos,
					modificar, total ou parcialmente, os presentes Termos e condições.
					É dever do utilizador consultar os Termos e Condições em cada acesso que efetue ao site, devendo
					abster-se de os
					utilizar caso de não concorde com as suas regras.
				</p>
				<p class="strong">
					6. PRIVACIDADE E DADOS PESSOAIS
				</p>
				<p>
					Os dados pessoais dos utilizadores serão recolhidos e tratados de acordo com as normas legais em
					vigor e de
					acordo com a Política de Privacidade constante do site e disponível <a :href="privacyLink"
																						   rel="noopener"
																						   target="_blank">aqui</a>.
				</p>
				<p class="strong">
					7. COMO CONTACTAR A YNEXUS
				</p>
				<p>
					Quaisquer dúvidas ou esclarecimentos sobre os presentes Termos e Condições devem ser dirigidas ao
					seguinte
					endereço de e-mail <a href="mailto:geral@ynexus.com">geral@ynexus.com</a>.
				</p>
				<p class="strong">
					8. LEI APLICÁVEL
				</p>
				<p>
					Os presentes Termos de Utilização são regulados pela Lei Portuguesa em vigor.
				</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name       : 'TermsPage',
	components : {},
	data() {
		return {
			isLoaded : false,
			domain   : window.location.hostname
		};
	},
	mounted() {
		let res = this.$store.state.resData.data;
		if (res.setup.privacy_name !== null && res.setup.privacy_email !== null && res.setup.privacy_address !== null) {
			this.isLoaded = true;
		} else {
			window.location.href = res.redirect_url;
		}
	}
};
</script>

<style lang="scss">
h1 {
	font-size: 1.6rem;
	font-weight: 700;
}

p, li {
	font-size: 0.85rem;

	&.strong {
		font-weight: 700;
		margin-top: 2rem;
	}
}

a {
	color: #343942;
	text-decoration: underline;
}

ol {
	li {
		span {
			text-decoration: underline;
		}
	}
}
</style>
